
    import { defineComponent } from 'vue';

    interface Breadcrumb {
        name: string;
        url: string;
    }

    export default defineComponent({
        name: 'breadcrumbs',
        props: {
            elements: {
                type: Array,
                default: [],
            },
        },

        data() {
            return {
                breadcrumbs: [
                    {url: '/', name: 'Home'}
                ],
            }
        },

        computed: {
            generatedBreadcrumbs() {
                const generatedBreadcrumbs: Breadcrumb[] = this.$props.elements.map((item: any) => {
                    return {
                        name: item.name,
                        url: '/' + item.url,
                    }
                })
                .map((item, index, array) => {
                    if (index === 0) {
                        return item;
                    }

                    const allPreviousArrayElements = array.slice(0, index + 1);
                    const combinedUrl = allPreviousArrayElements.map(item => item.url).join('');

                    return {
                        name: item.name,
                        url: combinedUrl,
                    };
                });

                generatedBreadcrumbs.unshift({
                    name: 'Home',
                    url: '/'
                });

                return generatedBreadcrumbs;
            }
        }
    })
