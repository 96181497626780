
    import { defineComponent, PropType } from 'vue';
    import { MediaPost } from '@/interfaces/MediaPost';

    export default defineComponent({
        props: {
            post: Object as PropType<MediaPost>,
        },

        directives: {
            linkify: {
                mounted(el) {
                    // This is 100% from https://github.com/maorbarel/v-linkify but with just the raw URL parts
                    // and nothing else because I couldn't get the package/directive to work
                    const urlPattern = /\b(?:https?|ftp):\/\/[a-z0-9-+&@#\/%?=~_|!:,.;]*[a-z0-9-+&@#\/%=~_|]/gim;

                    el.innerHTML = el.textContent.split(' ').map((text: any) => {
                        const matchedResults = [
                            ...text.matchAll(urlPattern),
                        ].flat();

                        if (matchedResults.length) {
                            matchedResults.forEach(result => {
                                if (result.match(urlPattern) !== null) {
                                    var prefix = result.toLowerCase().indexOf('http') === -1 && result.toLowerCase().indexOf('ftp') === -1 ? '//' : '';
                                    text = text.replace(result, `<a target="_blank" href="${prefix + result.trim()}">${result}</a>`)
                                }
                            });
                        }

                        return text;
                    }).join(' ')
                },
            }
        },
    });
