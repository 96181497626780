import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "breadcrumbs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock("ul", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.generatedBreadcrumbs, (breadcrumb) => {
      return (_openBlock(), _createBlock("li", { key: breadcrumb }, [
        _createVNode(_component_router_link, {
          to: {path: breadcrumb.url}
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(breadcrumb.name), 1)
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ]))
}